var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drop-item contextmenu-canvas",class:[
      {
        'is-floating': _vm.isFloat,
        disabled: _vm.isDisabled,
        'can-upload': !_vm.uploadDisabled,
        hover: _vm.isHover,
      },
      _vm.customClass,
    ],style:(_vm.positionStyle),on:{"dragend":_vm.onDrop,"dragenter":_vm.onDrag,"dragleave":_vm.onDrop,"dragover":_vm.onDrag,"drop":_vm.onDrop,"mouseout":function($event){_vm.isHover = false},"mouseover":function($event){_vm.isHover = true}}},[_c('b-upload',{attrs:{"disabled":_vm.isSelf || _vm.device.offline || _vm.uploadDisabled,"drag-drop":"","multiple":""},on:{"input":_vm.fileDroped},model:{value:(_vm.dropFiles),callback:function ($$v) {_vm.dropFiles=$$v},expression:"dropFiles"}},[_c('b-tooltip',{attrs:{"always":_vm.tipActive,"label":_vm.tipText,"position":_vm.tipPosition,"multilined":"","size":"is-small","type":"is-grey"}},[_c('div',{ref:"circleArea",staticClass:"circle-area",class:{ 'drag-over': _vm.dragOver },on:{"contextmenu":function($event){$event.stopPropagation();$event.preventDefault();return _vm.showContextMenu.apply(null, arguments)}}},[_c('div',{staticClass:"up-layer",class:{ 'is-online': !_vm.device.offline }},[_c('b-image',{staticClass:"is-48x48 mr-0 ml-0 no-click",class:_vm.stateClass,attrs:{"alt":_vm.device.name.displayName,"src":require(`@/assets/img/drop/${_vm.deviceIcon}.svg`)}})],1),_c('vue-ellipse-progress',{directives:[{name:"show",rawName:"v-show",value:(_vm.progress > 0),expression:"progress > 0"}],attrs:{"emptyThickness":2,"legend":false,"progress":_vm.progress,"size":80,"thickness":2,"animation":"default 0 0","emptyColor":"#ffffff","lineMode":"in-over"}})],1)]),_c('div',{ref:"deviceName",staticClass:"device-name has-text-full-03"},[_vm._v(" "+_vm._s(_vm.device.name.displayName)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }